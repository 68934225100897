//Custom Color
$primary: #585463;
$secondary:#d5c6cb;
$success: #3a845c;
$danger: #b85f51;
$warning: #9d6526;
$info: #c98942;
$alert: #f1c73f;
$blue: #5086bb;

$black: #333333;
$white: #ffffff;
$light: #f8f9fa;

//dark & light
$dark-primary: darken( $primary, 10% );
$light-primary: lighten( $primary, 10% );
$dark-secondary: darken( $secondary, 10% );
$light-secondary: lighten( $secondary, 10% );
$dark-success: darken( $success, 10% );
$light-success: lighten( $success, 10% );
$dark-danger: darken( $danger, 10% );
$light-danger: lighten( $danger, 10% );
$dark-warning: darken( $warning, 10% );
$light-warning: lighten( $warning, 10% );
$dark-info: darken( $info, 10% );
$light-info: lighten( $info, 10% );
$dark-alert: darken( $alert, 10% );
$light-alert: lighten( $alert, 10% );

// Override default color
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "black": $black
);

//
$h-header: 80px;
$h-toolbar: 40px;
$h-main-footer: 34px;


@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
