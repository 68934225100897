// Global Var
@import "_global-variables";

// Roboto from Google Font
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";

// Custom indicator icon
@import "assets/indic-icon/css/indicator.css";

// Custom range selector style
@import "assets/css/slider.css";

@import "node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

// Import ROBOTO
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


// DropDown Style
@import "../node_modules/@khajegan/angular2-multiselect-dropdown/themes/default.theme.css";
.selected-list {
  .c-btn {
    min-height: 45px !important;
  }

  .c-list .c-token {
    background: $success !important;
  }
}
.clear-all {
  display: none !important;
}

.modal {
  z-index: 1060 !important;
}

/*===============================================*/
/*======================COMMONS==================*/
/*===============================================*/

body {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}

  :focus {
    outline: 0px;
  }

  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

.content-wrapper {
  min-height: calc(100vh - #{$h-header} - 284px);
}

app-docs-list, app-maps-list {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  border: solid 1px $light-secondary;
  border-radius: 4px;
}

.close:focus{
  outline: 0px;
}

.cursor-pointer {
  cursor: pointer;
}


/*============NASCONDE ELEMENTI IN HOME===============*/
#last-update {
  .hide-in-home {
    display: none !important;
  }
  .show-in-home {
    display: block !important;
  }
  .hidden-in-home{
    visibility: hidden !important;
  }
}

.breadcrumb-wrapper{
  background-color: #e4e4e4;
  a {
    color: black;
  }
}


/*===============================================*/
/*=========CHART DIMENSIONS===============*/
/*===============================================*/

.chart-container {
  position: relative;
  margin: auto;
  min-height: 30vh;
}
.col-12 {
  .chart-container {
    min-height: 60vh !important;
  }
}


  /*dimensionamento dashboard*/
/*  .tile-wrapper canvas[style] {
    width:100% !important;
    //max-width: 100%!important;
    //min-height: 25vh;
  }

  !*dimensionamento modale*!
  .modal-body canvas {
    width:100% !important;
    height: 100% !important;
    object-fit: scale-down;
  }*/




/*===============================================*/
/*=========BOOTSTRAP CUSTOMIZATION===============*/
/*===============================================*/
.btn.btn-square {
  border-radius: 0;
}
 .badge-secondary:hover {
color:$black !important;
}
//.btn-info, .btn-success, .btn-success:hover, .badge-success

.col-border-separator{
border-right: transparent 10px solid;
}
.btn {
  font-weight: 700 !important;
  font-size: 14pt !important;
}

.btn-alert {
  background-color: $alert;
  color: white !important;
    &:hover {
    background-color: $dark-alert;
      color: white !important;
  }
}

.btn-light, .badge-light {
  border: solid 1px $secondary;
}

/*===============================================*/
/*============= SCROLLBAR CUSTOM ================*/
/*===============================================*/
/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/*===============================================*/
/*================== PAGINATION ================*/
/*===============================================*/
.pagination {
  display: inline-block;
  a {
    color: $black;
    float: left;
    padding: 6px 12px;
    text-decoration: none;
    transition: background-color .3s;
    border-bottom: solid 4px transparent;
    font-weight: 500;
    &.active {
      border-bottom: solid 4px $primary;
      color: $primary;
    }
    &:hover:not(.active) {
      background-color: $light;
    }
  }
}



.pagination a:hover:not(.active) {
  background-color: #ddd;
  cursor: pointer
}


/*CUSTOM MULTI SELECT*/
.list-area {
  display: flex;
  flex-direction: column-reverse;
}
.select-all {
  order: 2;
}
.list-filter {
  order: 3
}




/*==================== PRINT DASHBOARD ===================*/
.print-page {
  width: 297mm;
  //border: 1px solid darkgrey; //DEMO
  background: white;
  padding: 0mm 4mm;
  .chart-container {
    min-height: 100mm;
  }
  .chart-tool {
    display: none !important;
  }
  .tile-content {
    min-height: auto!important;
  }
  .t-header, .t-footer {
    //background-color: lightgrey; //DEMO
    text-align: center;
    padding: 3mm;
  }
  .t-header {
    padding-top: 12mm;
  }
  .t-footer {
       padding-bottom: 12mm;
     }
}
@media print {
.eis-header, .footer-bottom  {
    display: none;
  }
  .tile-wrapper {
    break-inside: avoid !important;
  }
  .loading-print {
    display: none !important;
  }
}

.map-wrapper {
  & > div {
    display: flex !important;
  }
  epHolder {
    display: flex !important;
  }
}

